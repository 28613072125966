import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Button,
    Col,
    Drawer,
    Dropdown,
    Flex,
    Form,
    Input,
    message,
    Modal,
    Progress,
    Row,
    Space,
    Upload,
    UploadFile,
    UploadProps
} from "antd";
import {SmileOutlined, UploadOutlined, UserOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import {baseUrl, fetchData, fetchDataSilent, installUrl, MAX_FILE_SIZE, postDataUser} from "../apis/api";
import {RcFile} from "antd/es/upload";
import TextArea from "antd/es/input/TextArea";
import {showAdmin} from "../admin/AppForAdmin";
import {useLocation} from "react-use";

export const getUserToken = (): string | null => {
    const rootElement = document.getElementById('root');
    if (rootElement) {
        return rootElement.getAttribute('data-token');
    }

    return localStorage.getItem('token');
};

export const getIsAdmin = (): boolean => {
    // 读取是否展现后台
    return showAdmin;
}

// 跳转到商店页面
const jumpInstall = () => {
    const userToken = getUserToken();
    if (userToken == null) {
        window.open(installUrl, '_blank');
    }
}
//跳转到新标签页（安装插件后）
const jumpNewTab = () => {
    window.open("", '_blank');
}


interface UserinfoProps {
    showUpload?:boolean

    updateResult?: () => void;
}

const UserInfo: React.FC<UserinfoProps> = ({showUpload=false, updateResult}) => {


    const navigate = useNavigate();

    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [showHomeButton, setShowHomeButton] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    const [open, setOpen] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [currentFile, setCurrentFile] = useState<string | null>(null);

    const [fileList, setFileList] = useState<UploadFile[]>([]);


    const [token, setToken] = useState<string|null>();
    const [nickname, setNickname] = useState<string|null>();
    const [avatar, setAvatar] = useState<string|null>();

    useEffect(() => {
        // 初次加载尝试从缓存中获取token 昵称 头像
        let storeToken = localStorage.getItem('token');
        let storeNickname = localStorage.getItem('nickname');
        let storeAvatar = localStorage.getItem('avatar');

        if (storeToken) {
            setToken(storeToken)
        }
        if (storeNickname) {
            setNickname(storeNickname)
        }
        if (storeAvatar) {
            setAvatar(storeAvatar)
        }
    }, []);



    // 打开修改信息对话框
    const openEdit = () => {
        setShowEditModal(true);
    }

    // 关闭修改信息对话框
    const closeEdit = () => {
        setShowEditModal(false);
    }

    // 提交
    const handleEditConfirm = async () => {
        if (nickname == null || nickname == ""){
            message.warning("昵称不可为空")!
            return;
        }
        await postDataUser("/user/edit-info", {nickname: nickname}, token);
        localStorage.setItem('nickname', nickname);

        setShowEditModal(false);
    }


    // 跳转到【我的收藏】页面
    const jumpSubscribe = () => {
        navigate('/subscribe/');
    }
    // 跳转到【上传壁纸】页面
    const jumpUpload = () => {
        navigate('/upload/');
    }
    // 跳转到【桌面工具】页面
    const jumpDesktop = () => {
        navigate('/desktop/');
    }
    // 跳转到【博客】页面（测试）
    const jumpTestBlog = () => {
        navigate('/blog/665eb29256d17e28093e8a82');
    }
    const getMenuItems = (token:string|null|undefined) => {
        const baseItems = [
        ];

        if (token != null && token != "NotLogin") {
            baseItems.push({
                key: '0',
                label: (
                    <a onClick={openEdit}>
                        修改信息
                    </a>
                ),
            });
            baseItems.push({
                key: '1',
                label: (
                    <a onClick={jumpSubscribe}>
                        我的收藏
                    </a>
                ),
            });
            baseItems.push({
                key: '2',
                label: (
                    <a onClick={jumpUpload}>
                        上传壁纸
                    </a>
                ),
            });
            baseItems.push({
                key: '100',
                label: (
                    <a onClick={logout}>
                        登出
                    </a>
                ),
            });
        // } else if (token == "NotLogin") {
        //     baseItems.push({
        //
        //         key: '99',
        //         label: (
        //             <a onClick={jumpNewTab}>
        //                 插件端登录
        //             </a>
        //         ),
        //     });
        } else {
            baseItems.push({

                key: '88',
                label: (
                    <a onClick={jumpWxLogin}>
                        微信登陆
                    </a>
                ),
            });
        }

        // baseItems.push({
        //     key: '99',
        //     label: (
        //         <a onClick={jumpDesktop}>
        //             动态壁纸桌面工具
        //         </a>
        //     ),
        // });

        return baseItems;
    };


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pageLink, setPageLink] = useState('');

// 跳转微信登陆
    const jumpWxLogin = async () => {
        try {
            const qrResponse = await fetchData("/wxopen/qrcode?source=JijianWeb", null);

            const width = 600;
            const height = 600;
            const left = (window.screen.width - width) / 2;
            const top = (window.screen.height - height) / 2;
            const authWindow = window.open(
                qrResponse.pageLink,
                '_blank',
                `width=${width},height=${height},left=${left},top=${top}`
            );

            let checkTime = 0
            let maxCheckTime = 20
            // 定时检查登陆状态
            const checkInterval = setInterval(async () => {
                // 检查弹窗的 URL，只有当授权成功重定向到你的回调时进行处理
                if (authWindow) {
                    const checkResponse = await fetchDataSilent("/wxopen/check-login?token="+qrResponse.token, null);
                    if (checkResponse !== null) {
                        message.success("登陆成功")
                        clearInterval(checkInterval);
                        authWindow.close();

                        // 将用户信息存储于本地。
                        localStorage.setItem('nickname', checkResponse.nickname);
                        localStorage.setItem('token', checkResponse.token);
                        localStorage.setItem('avatar', checkResponse.avatar);

                        setNickname(checkResponse.nickname);
                        setToken(checkResponse.token);
                        setAvatar(checkResponse.avatar);
                    }
                } else {
                    clearInterval(checkInterval);
                }

                checkTime = checkTime + 1
                if (checkTime >= maxCheckTime){
                    clearInterval(checkInterval);
                    if (authWindow){
                        authWindow.close();
                        message.warning("登陆超时，请重新获取二维码进行登陆")
                    }
                }

            }, 3000); // 每3秒检查一次

        } catch (error) {
            console.error("获取登陆二维码失败");
        }
    }

    // 登出
    const logout = () => {
        localStorage.removeItem('nickname');
        localStorage.removeItem('token');
        localStorage.removeItem('avatar');

        message.success("登出成功")

        // 刷新当前页面
        window.location.href = window.location.origin;

    }

    useEffect(() => {
        const currentToken = getUserToken();
        setToken(currentToken);
        // if (currentToken !== null) {
        //     console.log('User token:', currentToken);
        // } else {
        //     console.log('No token found');
        // }

        // 鉴定是否管理员
        if (getIsAdmin()) {
            // console.log("管理员登录");
            setIsAdmin(true)
        } else {
            setIsAdmin(false)
        }
    }, []);

    // 跳转到管理员页面
    const jumpAdmin = () => {
        navigate('/admin/');
    };

    // 跳转到主页面
    const jumpHome = () => {
        navigate('/');
    };

    // 通过路径检测是否需要展示“返回主页”按钮
    useEffect(() => {
        const path = window.location.hash.split('#')[1] || '/';
        setShowHomeButton(path !== '/');
    }, []);


    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    const [form] = Form.useForm();

    // 新版上传壁纸（带有本地进度条）
    const onCreateSubmit = async () => {
        try {
            const values = await form.validateFields();
            const fileList = values.file.fileList;

            setUploading(true);
            setProgress(0);

            for (const file of fileList) {
                if (file.originFileObj.size >= MAX_FILE_SIZE) {
                    message.error(`文件 "${file.name}" 超过20MB，请压缩或选择更小的文件。`);
                    setUploading(false);
                    setProgress(0);
                    return; // 停止上传过程
                }
                await uploadFile(values, file.originFileObj as RcFile);
            }

            message.success('壁纸上传完成');
            form.resetFields();
            setFileList([]);
            setUploading(false);
            setProgress(100);
            setCurrentFile(null);
        } catch (errorInfo) {
            console.error(errorInfo);
            message.error('请填写所有必填字段！');
            setUploading(false);
        } finally {
            setOpen(false);
            if (updateResult != undefined) {
                updateResult();
            }
        }
    };

    const handleFileChange: UploadProps['onChange'] = (info) => {
        const newFileList = info.fileList.map(file => {
            if (file.response) {
                // 修改 file 状态为 done
                file.status = 'done';
            }
            return file;
        });
        setFileList(newFileList);
    };

    const customRequest = ({ file, onSuccess }: any) => {
        // 使用 setTimeout 模拟文件上传并立即调用 onSuccess
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    const uploadFile = (values: any, file: RcFile) => {
        // 由于这个请求需要进度条，所以没有写在api.ts里面
        const authToken = getUserToken();
        if (authToken == null){
            message.warning('请在插件端登录后操作');
            return
        }

        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('name', values.name);
            formData.append('description', values.description);
            formData.append('tag', values.tag);

            const xhr = new XMLHttpRequest();
            xhr.open('POST', baseUrl+'/wallpaper/create', true);

            // 设置 Authorization 请求头
            xhr.setRequestHeader('Authorization', `${authToken}`);

            xhr.upload.onprogress = (event: ProgressEvent) => {
                if (event.lengthComputable) {
                    const percent = Math.round((event.loaded / event.total) * 100);
                    setProgress(percent);
                    setCurrentFile(file.name);
                }
            };

            xhr.onload = () => {
                if (xhr.status === 200) {
                    resolve(xhr.response);
                } else {
                    reject(xhr.response);
                }
            };

            xhr.onerror = () => {
                reject(xhr.response);
            };

            xhr.send(formData);
        });
    };

    return (
        <Flex align={"center"} justify={"flex-end"} style={{maxWidth:"300px", minWidth:"20vw", marginRight:"30px"}}>
            <div style={{display:"none"}}>占位符</div>
            {/*<Button*/}
            {/*    style={{marginRight:"10%"}}*/}
            {/*    onClick={jumpTestBlog}>测试博客*/}
            {/*</Button>*/}
            {(isAdmin && !showUpload &&
                <Button
                        style={{marginRight:"10%"}}
                        onClick={jumpAdmin}>前往后台
                </Button>
            )}
            {(showHomeButton &&
                <Button
                        style={{marginRight:"10%"}}
                        onClick={jumpHome}>返回主页
                </Button>
                )
            }
            {
                showUpload &&
                <Button type={"primary"}
                        style={{marginRight:"10%"}}
                        onClick={showDrawer}>上传
                </Button>
            }


            {
                avatar &&
                <Dropdown menu={{ items: getMenuItems(token) }} placement="bottom" arrow>
                    <Avatar size="large" src={avatar} style={{cursor:"pointer"}}/>
                </Dropdown>
            }
            {
                !avatar &&
                <Dropdown menu={{ items: getMenuItems(token) }} placement="bottom" arrow>
                    <Avatar size="large" icon={<UserOutlined/>} style={{cursor:"pointer"}}/>
                </Dropdown>
            }



            <Modal
                visible={showEditModal}
                footer={null}
                closable={true}
                onCancel={closeEdit}
                centered
            >
                <div style={{ textAlign: 'center', padding: '20px' }}>
                <span style={{ color: "#777", display: 'block', marginBottom: '20px' }}>
                    你可以在这里更改你的昵称。
                </span>
                    <Input
                        placeholder="输入新的昵称"
                        value={ nickname! }
                        onChange={(e) => setNickname(e.target.value)}
                        style={{ width: '80%', marginBottom: '20px' }}
                    />
                    <Button style={{marginLeft:"10px"}}
                        type="primary" onClick={handleEditConfirm}>
                        确认
                    </Button>
                </div>
            </Modal>


            <Drawer title="壁纸编辑" onClose={onClose} open={open}
                    extra={
                        <Space>
                            <Button onClick={onClose}>取消</Button>
                            <Button onClick={onCreateSubmit} type="primary" disabled={uploading}>
                                保存
                            </Button>
                        </Space>
                    }>

                <Modal
                    visible={uploading}
                    footer={null}
                    closable={false}
                    centered
                >
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                        <p>{currentFile ? `正在上传: ${currentFile}` : '准备上传...' }</p>
                        <Progress percent={progress} status="active" />
                        <span style={{color:"#777"}}>
                            提示：上传完成后仍需等待文件从服务器存到云端...
                        </span>
                    </div>
                </Modal>

                <Form layout="vertical" form={form}>
                        <span style={{color:"#777"}}>
                            提示：当前仅允许上传大小在20MB以内的壁纸文件
                        </span>
                    <br></br>
                    用户token：{getUserToken()}
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="file" label="上传壁纸">
                                <Upload
                                    name="wallpaper"
                                    accept="image/jpeg,image/jpg,image/png,image/bmp,image/webp,image/jfif,video/mp4"
                                    fileList={fileList}
                                    onChange={handleFileChange}
                                    customRequest={customRequest}
                                    multiple={true}
                                >
                                    <Button>
                                        <UploadOutlined /> 点击上传
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="壁纸名称"
                                rules={[{ required: true, message: '请输入壁纸的名称！' }]}
                                initialValue={"壁纸"}
                            >
                                <Input placeholder="壁纸的标题"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="tag"
                                label="标签"
                                rules={[{ required: true, message: '请输入壁纸的标签！' }]}
                                initialValue={"自定义，上传"}
                            >
                                <Input placeholder="壁纸的标签，逗号分割" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="description"
                                label="描述"
                                rules={[{ required: true, message: '请输入壁纸的描述！' }]}
                                initialValue={"用户上传的壁纸"}
                            >
                                <TextArea placeholder="壁纸详细描述" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </Flex>
    );
};

export default UserInfo;
