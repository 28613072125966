
import axios from "axios";
import {message} from "antd";

// 应用商店链接
export const installUrl = "https://microsoftedge.microsoft.com/addons/detail/moogefiaeahgcncemcbicfegolkobimk"

// 后端服务URL
export const baseUrl = "https://api.protab.cn"
// export const baseUrl = "http://192.169.3.10:9814"

export const MAX_FILE_SIZE = 20 * 1024 * 1024; // 文件最大限制 20MB

const apiGet = axios.create({
    baseURL: baseUrl,
    timeout: 40000,
});
const apiPost = axios.create({
    baseURL: baseUrl,
    timeout: 40000,
    headers: {
        // 后端普遍接收form-data,如果不配置，默认发送的是json，会收不到
        'Content-Type': 'multipart/form-data',
    },
});

export const fetchData = async (url: string, token: string|null = null) => {
    try {
        // 设置 Authorization 头部
        if (token != null) {
            apiGet.defaults.headers.common['Authorization'] = token;
        }

        const response = await apiGet.get(url);
        if (response.data.code === 0){

        } else {
            console.log("请求失败，"+response.data.msg)
            // 使用 antd 的 notification 组件显示错误消息
            message.error(response.data.msg)
        }
        return response.data.data;
    } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        message.error("未知错误")
        throw error;
    }
};


export const fetchDataSilent = async (url: string, token: string|null = null) => {
    try {
        // 设置 Authorization 头部
        if (token != null) {
            apiGet.defaults.headers.common['Authorization'] = token;
        }

        const response = await apiGet.get(url);
        if (response.data.code === 0){

        } else {
            console.log("请求失败，"+response.data.msg)
        }
        return response.data.data;
    } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        throw error;
    }
};

export const postDataAdmin = async (url: string,data: any) => {
    try {
        // 设置 Authorization 头部
        apiPost.defaults.headers.common['Authorization'] = `6667ad12c97c0815460764da`;

        const response = await apiPost.post(url,data);
        if (response.data.code === 0){
            message.success(response.data.msg)
        } else {
            console.log("请求失败，"+response.data.msg)
            // 使用 antd 的 notification 组件显示错误消息
            message.error(response.data.msg)
        }
        return response.data.data;
    } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        message.error("未知错误")
        throw error;
    }
};


export const postDataUser = async (url: string,data: any,token: string|null = null) => {
    try {
        // 设置 Authorization 头部
        if (token != null) {
            apiPost.defaults.headers.common['Authorization'] = token;
        }

        const response = await apiPost.post(url,data);
        if (response.data.code === 0){
            message.success(response.data.msg)
        } else {
            console.log("请求失败，"+response.data.msg)
            // 使用 antd 的 notification 组件显示错误消息
            message.error(response.data.msg)
        }
        return response.data.data;
    } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        message.error("未知错误")
        throw error;
    }
};

export const postDataUserSilent = async (url: string,data: any,token: string|null = null) => {
    // 不显示请求结果
    try {
        // 设置 Authorization 头部
        if (token != null) {
            apiPost.defaults.headers.common['Authorization'] = token;
        }

        const response = await apiPost.post(url,data);
        return response.data.data;
    } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        throw error;
    }
};
